import React from 'react';
import { useState } from 'react';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {GET_DIVIDEND_TRANSACTION_REPORTS,
        DIVIDEND_TRANSACTION_REPORT_DOWNLOAD_AS_EXCEL} from "../../constants/uri";
import {checkHttpStatus, post} from "../../utils/RestUtilsWithAxios";
import Paper from "@material-ui/core/Paper";
import MyTable from "../../components/MyTable";
import { makeStyles } from "@material-ui/core/styles/index";
import FilterPanel from "./FilterPanel";
import * as DownloadUtils from "../../utils/downloadUtils";
import * as Globals from "../../constants/globals";
import { alertBox } from "../../components/BoxProvider";
import { stableSort, getSorting } from "../../utils/ExportUtils";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import DownloadButtons from "../../components/PdfAndExcelDownloadButtons";
import { printThisPage } from "../../utils/ExportUtils";
import Typography from "@material-ui/core/es/Typography/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
        width: '100%',
  },
  paper: {
    marginBottom: theme.spacing(2),
        margin:'auto',
    padding: 30,
        paddingTop:20
  },
  form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

/**
 * DividendReport
 * @param props
 * @return {*}
 * @constructor
 */
function DividendTransactions(props) {
  /**
   * prepareItemArray
   * @param array
   * @return {Array}
   */
  function prepareItemArray(array) {
    var setArray = [];
        for(var i=0;i<array.length;i++){
      var valueItem = {};
      valueItem.values = [];
      valueItem.values.push(array[i].islemTarihi);
      valueItem.values.push(array[i].ihrTitle);
      valueItem.values.push(array[i].tax);
      valueItem.values.push(array[i].isin + " - " + array[i].isinDesc);
      valueItem.values.push(array[i].exchCode);
      valueItem.values.push(array[i].addDefCode);
      valueItem.values.push(array[i].ykTitle);
      valueItem.values.push(array[i].mulkMbrAccNo);
      valueItem.values.push(array[i].kullanilanBakiye);
      valueItem.values.push(array[i].brutOran);
      valueItem.values.push(array[i].netOran);
      valueItem.values.push(array[i].temettu);

      setArray.push(valueItem);
    }
    return stableSort(setArray,getSorting("asc",0,[0]));
      
  }

  const headRows = [
        { id: 'islemTarihi', numeric: false, date: true, disablePadding: false, label: 'İşlem Tarihi' },
        { id: 'ihrTitle', numeric: false, disablePadding: false, label: 'İhraççı' },
        { id: 'tax', numeric: false, disablePadding: false, label: 'İhraççı VKN' },
        { id: 'isinAndIsınDesc', numeric: false, disablePadding: false, label: 'Kıymet Adı' }, 
        { id: 'exchCode',  numeric: false, disablePadding: false, label: 'Menkul Kıymet Kodu'},
        { id: 'addDefCode',  numeric: false, disablePadding: false, label: 'Ek Tanım ' },
        { id: 'ykTitle',  numeric: false, disablePadding: false, label: 'Üye' },
        { id: 'mulkMbrAccNo',  numeric: false, disablePadding: false, label: 'Hesap Numarası' },
        { id: 'kullanilanBakiye',  numeric: false, disablePadding: false, label: 'Kullanılan Bakiye' },
        { id: 'BrutOran',  numeric: false, disablePadding: false, label: 'Brüt Oran(%)'},
        { id: 'NetOran',  numeric: false, disablePadding: false, label: 'Net Oran(%)'},
        { id: 'temettu',  numeric: false, disablePadding: false, label: 'Temettü(TL)' },

  ];

    const alignment = ['left', 'left','right','right'];

  /**
   * filterReports
   * @param selectedBeginDate
   * @param selectedEndDate
   * @param selectedIhrMember
   */
    function filterReports(selectedBeginDate,selectedEndDate,selectedIhrMember) {
        var endDate = new Date(selectedEndDate.substring(0,4)+'-'+selectedEndDate.substring(4,6)+'-'+selectedEndDate.
        substring(6,8));
        var beginDate = new Date(selectedBeginDate.substring(0,4)+'-'+selectedBeginDate.
        substring(4,6)+'-'+selectedBeginDate.substring(6,8));

    if (beginDate.getTime() > endDate.getTime()) {
      alertBox(
        "",
        "Başlangıç Tarihi Bitiş Tarihinden büyük olamaz.",
        Globals.ALERT_ERROR,
        props.intl
      );
      return;
    }


    post(GET_DIVIDEND_TRANSACTION_REPORTS, {
      beginDate: selectedBeginDate,
      endDate: selectedEndDate,
    })
      .then(function(response) {
        setdividendTransactionMapList([]);
        setdividendTransactionMapList(response.data.dividendTransactionMapList);
        setdividendTransactionList(response.data.dividendTransactionList);
      })
      .catch(function(error) {
        checkHttpStatus(error, props.intl);
      });
  }

  /**
   * download
   * @param serviceName
   */
  function download(serviceName) {
    post(serviceName, {
      dividendTransactionResponseList: dividendTransactionList,
    })
      .then(function(response) {
        if (serviceName === DIVIDEND_TRANSACTION_REPORT_DOWNLOAD_AS_EXCEL)
          DownloadUtils.downloadFileByContentExcel(
            response.data,
            "Temettü İşlemleri.xls",
            Globals.CONTENT_TYPE_EXCEL
          );
      })
      .catch(function(error) {
        checkHttpStatus(error, props.intl);
      });
  }

  const [dividendTransactionMapList, setdividendTransactionMapList] = useState([]);
  const [dividendTransactionList, setdividendTransactionList] = useState([]);
  const classes = useStyles();

  const AccordionPanel = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
      setIsOpen((prev) => !prev);
    };

    return (
      <div style={{ marginBottom: "10px" }}>
        <Button
          variant="contained"
          onClick={toggleAccordion}
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "50px",
          }}
          className="cardHeader"
        >
      
      {title.replace(/^\[|\]$/g, "")}  TL
          {isOpen ? (
            <ExpandLessIcon style={{ marginLeft: "auto" }} />
          ) : (
            <ExpandMoreIcon style={{ marginLeft: "auto" }} />
          )}
        </Button>
        <Collapse in={isOpen}>
          <Paper
            elevation={2}
            style={{
              padding: "16px",
              marginTop: "5px",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <MyTable
              expandable={false}
              intl={props.intl}
              tableRows={prepareItemArray(content)}
              headRows={headRows}
              alignment={alignment}
              defaultSort="asc"
              defaultSortIndex={0}
              dateTimeColumns={[0]}
              printOption={false}
              printHeader="TEMETTÜ BİLGİLERİ RAPORU"
            />
          </Paper>
        </Collapse>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <FilterPanel
          initOnOpen={true}
          filterReports={filterReports}
          ihrMembers={props.ihrMembers}
        />

        {undefined != dividendTransactionMapList &&
        Object.keys(dividendTransactionMapList).length > 0 ? (
          Object.keys(dividendTransactionMapList).map((item, index) => (
            <AccordionPanel
              key={index}
              title={item}
              content={dividendTransactionMapList[item]}
            />
          ))
        ) : (
          <Typography variant="subtitle1">
            {props.intl.formatMessage({
              id: "RIGHTS_INFORMATION.MESSAGE.DATA_NOT_FOUND",
            })}
          </Typography>
        )}
        {undefined != dividendTransactionList ? (
          <DownloadButtons
            pdfOnClick={() =>
              printThisPage(
                prepareItemArray(dividendTransactionList),
                headRows,
                "TEMETTU BİLGİLERİ RAPORU"
              )
            }
            excelOnClick={() =>
              download(DIVIDEND_TRANSACTION_REPORT_DOWNLOAD_AS_EXCEL)
            }
            style={{ marginBottom: 8 }}
          />
        ) : null}
      </Paper>
    </div>
  );
}

/**
 * injectIntl
 */
export default injectIntl(connect(null)(DividendTransactions));
