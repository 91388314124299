
export const BASE_URL = window.location.origin.startsWith('http://localhost')
  ? 'http://localhost:8080/'
  :  window.location.origin+'/';

export const GET_PARAMETER_DATA                          = `${BASE_URL}api/parameter/get-parameter-data`;
export const GET_PARAMETER_LIST                          = `${BASE_URL}api/parameter/get-parameter-list`;
export const GET_CONFIG_PARAM                            = `${BASE_URL}auth/get-config-param`;

export const LOGIN                                       = `${BASE_URL}auth/confirm-login`;
export const CAPTCHA                                     = `${BASE_URL}auth/captcha`;
export const EDEVLET_LOGIN                               = `${BASE_URL}auth/login-by-edevlet`;
export const EDEVLET_CONFIRM_LOGIN                       = `${BASE_URL}auth/confirm-edevlet-login`;
export const MAINTENANCE_CONTROL                         = `${BASE_URL}auth/maintenance-control`;

export const UPDATE_PASSWORD                             = `${BASE_URL}api/update-password`;
export const UPDATE_INVESTOR_PASSWORD_FOR_NINETY_DAY_INTERVAL   = `${BASE_URL}api/update-password-ninety-day-interval`;
export const UPDATE_GREETING_MESSAGE                     = `${BASE_URL}api/update-greeting-message`;

export const SEND_APPROVE_CODE                           = `${BASE_URL}api/approve/send-approvecode`;
export const CREATE_NEW_USER                             = `${BASE_URL}api/approve/create-new-user`;
export const GET_LOGIN_CODE                              = `${BASE_URL}api/approve/get-login-code`;
export const GET_LOGIN_SMS_CODE                          = `${BASE_URL}api/approve/should-send-code-to-sms`;
export const GET_LOGIN_EMAIL_CODE                        = `${BASE_URL}api/approve/should-send-code-to-email`;
export const GET_CHANGE_PASSWORD_CODE                    = `${BASE_URL}api/get-changePassword-code`;
export const CONFIRM_CHANGE_PASSWORD_CODE                = `${BASE_URL}api/confirm-changePassword-code`;
export const CONFIRM_LOGIN_CODE                          = `${BASE_URL}api/approve/confirm-login-code`;
export const GET_UNLOCK_CODE                             = `${BASE_URL}auth/get-mail-to-unlock`;
export const CONFIRM_UNLOCK_CODE                         = `${BASE_URL}auth/confirm-to-unlock`;
export const CONFIRM_UNLOCK_CODE_DEBUG                   = `${BASE_URL}auth/debug/debug-confirm-to-unlock`;
export const CONFIRM_LOGIN_CODE_DEBUG                    = `${BASE_URL}api/approve/debug/debug-confirm-login-code`;
export const CONFIRM_LOGIN_CODE_DEBUG2                   = `${BASE_URL}api/approve/debug/debug-confirm-for-register`;
export const GET_LOGIN_CODE_PURE                         =  '/api/approve/get-login-code';
export const CONFIRM_LOGIN_CODE_PURE                     =  '/api/approve/confirm-login-code';
export const CONFIRM_APPROVE_CODE                        = `${BASE_URL}api/approve/confirm-approve-code`;
export const REFRESH_SESSION                             = `${BASE_URL}api/token/refresh`;

export const GET_CODE_FOR_UPDATE                         = `${BASE_URL}api/get-confirm-code-for-update`;
export const CONFIRM_CODE_FOR_UPDATE                     = `${BASE_URL}api/confirm-code-for-update`;
export const GET_MAIL_AND_GSM                            = `${BASE_URL}api/get-mail-and-gsm`;

export const GET_ACCOUNT_INFO                            = `${BASE_URL}api/balance/get-account-info`;
export const ACCOUNT_INFO_DOWNLOAD_AS_EXCEL              = `${BASE_URL}api/balance/account-download-excel`;
export const ACCOUNT_INFO_DOWNLOAD_AS_PDF                = `${BASE_URL}api/balance/account-download-pdf`;
export const GET_PARTNERS_BY_ACCOUNT                     = `${BASE_URL}api/balance/get-partners`;

export const GET_MENU_ITEMS                              = `${BASE_URL}api/get-dynamic-menu-items`;

/* SESSION */
export const LOGOUT                                      = `${BASE_URL}api/session/logout`;
export const SESSION_KEEP_ALIVE_URL                      = `${BASE_URL}api/session/keepAlive`;

export const GET_GENERIC_PORTFOLIO                       = `${BASE_URL}api/balance/balance-portfolio`;
export const GET_SECURITY_BASED_PORTFOLIO                = `${BASE_URL}api/balance/balance-stock`;
export const GET_INTERMEDIARY_BASED_PORTFOLIO            = `${BASE_URL}api/balance/balance-intermediary`;
export const GET_NO_DISPOSAL_PORTFOLIO                   = `${BASE_URL}api/balance/securities-disposal`;
export const GET_PIE_CHART_DATA                          = `${BASE_URL}api/balance/balance-total-value-stock`;
export const GET_SECURITIES_PIE_CHART_DATA               = `${BASE_URL}api/balance/balance-total-value-securities`;
export const GET_INTERMEDIARY_PIE_CHART_DATA             = `${BASE_URL}api/balance/balance-total-value-intermediary`;
export const GET_DISTRAINT_INFO                          = `${BASE_URL}api/balance/investor-distraint`;
export const DOWNLOAD_EXCEL_GENERIC_PORTFOLIO            = `${BASE_URL}api/balance/portfolio-download-excel`;
export const DOWNLOAD_PDF_GENERIC_PORTFOLIO              = `${BASE_URL}api/balance/portfolio-download-pdf`;
export const DOWNLOAD_EXCEL_SECURITY_BASED_PORTFOLIO     = `${BASE_URL}api/balance/stock-download-excel`;
export const DOWNLOAD_PDF_SECURITY_BASED_PORTFOLIO       = `${BASE_URL}api/balance/stock-download-pdf`;
export const DOWNLOAD_EXCEL_INTERMEDIARY_BASED_PORTFOLIO = `${BASE_URL}api/balance/intermediary-download-excel`;
export const DOWNLOAD_PDF_INTERMEDIARY_BASED_PORTFOLIO   = `${BASE_URL}api/balance/intermediary-download-pdf`;
export const DOWNLOAD_EXCEL_NO_DISPOSAL_PORTFOLIO        = `${BASE_URL}api/balance/securities-disposal-download-excel`;
export const DOWNLOAD_PDF_NO_DISPOSAL_PORTFOLIO          = `${BASE_URL}api/balance/securities-disposal-download-pdf`;
export const DOWNLOAD_EXCEL_DISTRAINT_INFO               = `${BASE_URL}api/balance/investor-distraint-download-excel`;

export const GET_ACCOUNT_MEMBERS                         = `${BASE_URL}api/balance/get-member-list`;
export const GET_ACCOUNTS                                = `${BASE_URL}api/balance/get-account-list`;
export const GET_SUB_ACCOUNTS                            = `${BASE_URL}api/balance/get-sub-account-list`;
export const GET_ISINS                                   = `${BASE_URL}api/balance/get-isin-list`;
export const GET_TRANSACTIONS                            = `${BASE_URL}api/balance/get-transactions`;
export const TRANSACTIONS_DOWNLOAD_AS_EXCEL              = `${BASE_URL}api/balance/transactions-download-excel`;
export const TRANSACTIONS_DOWNLOAD_AS_PDF                = `${BASE_URL}api/balance/transactions-download-pdf`;

export const PLEDGE_GET_MEMBER_LIST                      = `${BASE_URL}api/pledge/get-member-info`;
export const PLEDGE_GET_ACCOUNTS_FOR_MEMBER              = `${BASE_URL}api/pledge/get-account-code`;
export const GET_PLEDGE_DATA                             = `${BASE_URL}api/pledge/get-pledge-collateral`;
export const PLEDGE_COLLATERAL_DOWNLOAD_AS_EXCEL         = `${BASE_URL}api/pledge/pledge-download-excel`;
export const PLEDGE_COLLATERAL_DOWNLOAD_AS_PDF           = `${BASE_URL}api/pledge/pledge-download-pdf`;

export const GET_LENDING_DATA                            = `${BASE_URL}api/borrowing/get-borrowing-activity`;
export const BORROWING_ACTIVITY_DOWNLOAD_AS_EXCEL        = `${BASE_URL}api/borrowing/download-excel`;
export const BORROWING_ACTIVITY_DOWNLOAD_AS_PDF          = `${BASE_URL}api/borrowing/download-pdf`;

export const GET_AVAILABLE_LIMIT                         = `${BASE_URL}api/limit/memberLimitQuery`;
export const LIST_MEMBER_TRANSACTION                     = `${BASE_URL}api/kfs/listMemberTransaction`;
export const KFS_TRANSACTION_DOWNLOAD_EXCEL               = `${BASE_URL}api/kfs/kfs-transaction-download-excel`;

export const GET_INVESTOR_BLOCKAGE_DATA                  = `${BASE_URL}api/blockage/get-blockage-data`;
export const SET_OR_CANCEL_BLOCKAGE_DATA                 =  `${BASE_URL}api/blockage/set-investor-blockage`;

export const GET_EGK_INFOS                               = `${BASE_URL}api/hk/get-egk-infos`;
export const GET_TEMETTU_INFOS                           = `${BASE_URL}api/hk/get-temettu-infos`;
export const GET_SERMAYE_INFOS                           = `${BASE_URL}api/hk/get-sermaye-infos`;
export const GET_REDEMPTION_COUPON_PAYMENT_INFOS         = `${BASE_URL}api/hk/get-redemption-coupon-payment-infos`;

export const EGK_INFOS_DOWNLOAD_AS_EXCEL                 = `${BASE_URL}api/hk/egk-download-excel`;
export const EGK_INFOS_DOWNLOAD_AS_PDF                   = `${BASE_URL}api/hk/egk-download-pdf`;
export const TEMETTU_INFOS_DOWNLOAD_AS_EXCEL             = `${BASE_URL}api/hk/temettu-download-excel`;
export const TEMETTU_INFOS_DOWNLOAD_AS_PDF               = `${BASE_URL}api/hk/temettu-download-pdf`;
export const SERMAYE_INFOS_DOWNLOAD_AS_EXCEL             = `${BASE_URL}api/hk/sermaye-download-excel`;
export const SERMAYE_INFOS_DOWNLOAD_AS_PDF               = `${BASE_URL}api/hk/sermaye-download-pdf`;
export const RCP_INFOS_DOWNLOAD_AS_EXCEL                 = `${BASE_URL}api/hk/redemption_coupon_payment_infos-download-excel`;
export const RCP_INFOS_DOWNLOAD_AS_PDF                   = `${BASE_URL}api/hk/redemption_coupon_payment_infos-download-pdf`;

export const GET_REP_FLAG                                = `${BASE_URL}api/agentship/get-rep-flag`;
export const SET_REP_FLAG                                = `${BASE_URL}api/agentship/set-rep-flag`;

// AUTOMATIC_BLOCKAGE
export const GET_AUTOMATIC_BLOCKAGE_ALL_ISIN_TYPES       = `${BASE_URL}api/blockage/get-isin-types-all`;
export const GET_AUTOMATIC_BLOCKAGE_MEMBERS              = `${BASE_URL}api/blockage/get-members`;
export const GET_AUTOMATIC_BLOCKAGE_ISIN_BY_ISIN_TYPES   = `${BASE_URL}api/blockage/get-isin-isintypes`;
export const GET_AUTOMATIC_BLOCKAGE_ISIN_MEMBER          = `${BASE_URL}api/blockage/get-isin-member`;
export const GET_AUTOMATIC_BLOCKAGE_ISIN_TYPES_MEMBER    = `${BASE_URL}api/blockage/get-isin-types-member`;
export const GET_AUTOMATIC_BLOCKAGE_ADD_DEF_CODES        = `${BASE_URL}api/blockage/get-adddefcodes`;
export const GET_AUTOMATIC_BLOCKAGE_ACCOUNTS             = `${BASE_URL}api/blockage/get-accounts`;
export const GET_AUTOMATIC_BLOCKAGE_ISIN_TYPES_ACCOUNT   = `${BASE_URL}api/blockage/get-isin-types-account`;
export const GET_AUTOMATIC_BLOCKAGE_ISIN_ACCOUNT         = `${BASE_URL}api/blockage/get-isin-account`;
export const GET_AUTOMATIC_BLOCKAGE_AMOUNT               = `${BASE_URL}api/blockage/get-amount`;
export const SET_AUTOMATIC_BLOCKAGE                      = `${BASE_URL}api/blockage/set-automatic-blockage`;
export const GET_AUTOMATIC_BLOCKAGE_DATA                 = `${BASE_URL}api/blockage/get-automatic-blockage-data`;
export const CANCEL_AUTOMATIC_BLOCKAGE                   = `${BASE_URL}api/blockage/cancel-automatic-blockage`;

export const GET_IHR_MEMBERS                              = `${BASE_URL}api/hk/get-ihr-members`;
export const GET_DI_IHR_MEMBERS                           = `${BASE_URL}api/hk/get-debt-instruments-ihr-members`;
export const GET_CURRENT_GK_REPORTS                       = `${BASE_URL}api/hk/get-current-gk-reports`;
export const GET_OLD_GK_REPORTS                           = `${BASE_URL}api/hk/get-old-gk-reports`;
export const GKREPORT_DOWNLOAD_AS_EXCEL                   = `${BASE_URL}api/hk/gk-download-excel`;
export const GKREPORT_DOWNLOAD_AS_PDF                     = `${BASE_URL}api/hk/gk-download-pdf`;

export const GET_GK_DETAIL                                = `${BASE_URL}api/hk/get-gk-detail`;
export const GET_DOCUMENT_INFOS                           = `${BASE_URL}api/hk/get-document-infos/`;
export const GET_TEMETTU_REPORTS                          = `${BASE_URL}api/hk/get-temettu-reports`;
export const TEMETTU_REPORT_DOWNLOAD_AS_EXCEL             = `${BASE_URL}api/hk/temettu-download-excel`;
export const TEMETTU_REPORT_DOWNLOAD_AS_PDF               = `${BASE_URL}api/hk/temettu-download-pdf`;

export const GET_SERMAYE_ARTIRIM_REPORTS                  = `${BASE_URL}api/hk/get-sermaye-artirim-reports`;
export const SERMAYE_ARTIRIM_REPORT_DOWNLOAD_AS_EXCEL     = `${BASE_URL}api/hk/sermaye-artirim-download-excel`;
export const SERMAYE_ARTIRIM_REPORT_DOWNLOAD_AS_PDF       = `${BASE_URL}api/hk/sermaye-artirim-download-pdf`;

export const GET_DEBT_INSTRUMENTS_REPORTS                 = `${BASE_URL}api/hk/get-debt-instruments-reports`;
export const DEBT_INSTRUMENTS_REPORT_DOWNLOAD_AS_EXCEL    = `${BASE_URL}api/hk/debt-instruments-download-excel`;
export const DEBT_INSTRUMENTS_REPORT_DOWNLOAD_AS_PDF      = `${BASE_URL}api/hk/debt-instruments-download-pdf`;

export const GET_SERMAYE_AZALTIM_REPORTS                  = `${BASE_URL}api/hk/get-sermaye-azaltim-reports`;
export const SERMAYE_AZALTIM_REPORT_DOWNLOAD_AS_EXCEL     = `${BASE_URL}api/hk/sermaye-azaltim-download-excel`;
export const SERMAYE_AZALTIM_REPORT_DOWNLOAD_AS_PDF       = `${BASE_URL}api/hk/sermaye-azaltim-download-pdf`;
export const DOWNLOAD_DOCUMENT                            = `${BASE_URL}api/hk/download-document/`;

export const GET_ABS_SETTINGS                             = `${BASE_URL}api/notification/get-ecas-settings`;
export const UPDATE_NOTIFICATION_PREFERENCES              = `${BASE_URL}api/notification/update-notification-preferences`;
export const GET_YRTS_NOTIFICATION_SETTINGS               = `${BASE_URL}api/notification/get-yrts-notification-settings`;
export const SAVE_UPDATE_YRTS_NOTIFICATION_SETTINGS       = `${BASE_URL}api/notification/save-update-yrts-notification-settings`;
export const GET_ANNOUNCEMENT                             = `${BASE_URL}api/notification/get-announcements`;
export const GET_REPORT_SUBMISSION                        = `${BASE_URL}api/notification/get-report-submission`;

export const GET_ATTORNEYS                                = `${BASE_URL}api/disclosure/get-attorneys`;
export const GET_KAP_MEMBERS                              = `${BASE_URL}api/disclosure/get-kap-members`;
export const SAVE_KAP_DECLARATION                         = `${BASE_URL}api/disclosure/save-kap-declaration`;
export const GET_KAP_ATTACHMENT                           = `${BASE_URL}api/disclosure/get-attachment/`;

export const ACIKRIZA_BEYAN                               = `${BASE_URL}api/yrts/riza-beyan`;
export const ACIKRIZA_SORGU                               = `${BASE_URL}api/yrts/riza-sorgu`;
export const YRTS_QUERY                                   = `${BASE_URL}api/yrts/yrts-query`;
export const YRTS_DETAIL_QUERY_DOWNLOAD_EXCEL             = `${BASE_URL}api/yrts/yrts-detail-query-download-excel`;

export const KFS_PORTFOLIO_DOWNLOAD_EXCEL                 = `${BASE_URL}api/balance/kfs-portfolio-download-excel`;
export const ELUS_PORTFOLIO_DOWNLOAD_EXCEL                = `${BASE_URL}api/balance/elus-portfolio-download-excel`;

export const GET_PROMOTION                                = `${BASE_URL}api/promotion/get-promotion`;
export const GET_PROMOTION_APP                                = `${BASE_URL}api/promotion/get-promotionApp`;
export const APP_PROMOTION                                = `${BASE_URL}api/promotion/apply`;
export const PROMOTION_REPORT_DOWNLOAD_AS_EXCEL           = `${BASE_URL}api/promotion/promotion-log-download-excel`;

export const GET_HPKS_BALANCES                            = `${BASE_URL}api/hpks/balance`;
export const POST_HPKS_TAKE_OVER                          = `${BASE_URL}api/hpks/take-over`;
export const GET_HPKS_GM                                  = `${BASE_URL}api/hpks/gm`;
export const GET_HPKS_ID_TYPES                            = `${BASE_URL}api/hpks/id-types`;
export const GET_HPKS_COUNTRIES                           = `${BASE_URL}api/hpks/countries`;
export const GET_HPKS_CITIES                              = `${BASE_URL}api/hpks/cities`;
export const GET_HPKS_COUNTIES                            = `${BASE_URL}api/hpks/counties`;
export const DOWNLOAD_EXCEL_HPKS_BALANCES                 = `${BASE_URL}api/hpks/hpks-balances-download-excel`;
export const DOWNLOAD_EXCEL_HPKS_GM                       = `${BASE_URL}api/hpks/hpks-gm-download-excel`;

// ELUS
export const ELUS_QUERY                                   = `${BASE_URL}api/elus/query`;
export const ELUS_GET_MEMBERS                             = `${BASE_URL}api/elus/get-members`;
export const ELUS_GET_ACCOUNTS                            = `${BASE_URL}api/elus/get-accounts`;
export const ELUS_GET_SEC_TYPES                           = `${BASE_URL}api/elus/get-sec-types`;

export const REQUEST_FORM_LINK                          = `${BASE_URL}api/contactUs/get-link`;
export const REQUEST_FORM_REPORT_LINK                         = `${BASE_URL}api/contactUs/get-report`;
export const ELUS_GET_ISINS                               = `${BASE_URL}api/elus/get-isins`;
export const ELUS_GET_DOCUMENT                            = `${BASE_URL}api/elus/download-document`;


export const GET_ADMIN_USERS                              = `${BASE_URL}api/admin/get-users`;
export const DELETE_ADMIN_USERS                           = `${BASE_URL}api/admin/delete`;
export const GET_USER_INFO                                = `${BASE_URL}api/admin/user-info`;
export const SAVE_ADMIN_USER                              = `${BASE_URL}api/admin/save`;

export const GET_CONFIG_PARAMS                            = `${BASE_URL}api/admin/get-config-params`;
export const SAVE_CONFIG_PARAM                            = `${BASE_URL}api/admin/save-config-param`;
export const DELETE_CONFIG_PARAM                          = `${BASE_URL}api/admin/delete-config-param`;


//Survey API
export const GET_ACTIVE_SURVEY_STATUS                     =`${BASE_URL}api/survey/get-survey-status`;
export const SAVE_SURVEY                                  =`${BASE_URL}api/survey/save-survey`;

//PendingTrade
export const GET_PENDING_TRADE_LIST                       =`${BASE_URL}api/pending-trade/get-member-info`;

//DIVIDEND_TRANSACTION_REPORT
export const GET_DIVIDEND_TRANSACTION_REPORTS               = `${BASE_URL}api/dividend/dividend-transaction-reports`;
export const DIVIDEND_TRANSACTION_REPORT_DOWNLOAD_AS_EXCEL  = `${BASE_URL}api/dividend/dividend-download-excel`;
 

