import React from "react";
import { Dialog, DialogTitle, DialogContentText, DialogActions, DialogContent } from "@material-ui/core";
import { injectIntl } from "react-intl";
import { actions } from "../../../app/store/ducks/auth.duck";
import { useDispatch } from "react-redux";

import Button from '@material-ui/core/Button';
function SafeExit(props) {
  const [openLogoutModal, setOpenLogoutModal] = React.useState(false);
  const dispatch = useDispatch();


  React.useEffect(() => {
    setOpenLogoutModal(true)
  }, []);

  const goBack = () => {
    window.history.back();
  }


  return (
    <>
      <Dialog
        open={openLogoutModal}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        style={{ textAlign: "left" }}
      >
        <DialogTitle>
          {props.intl.formatMessage({ id: "AUTH.LOGOUT_TITLE" })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.intl.formatMessage({ id: "AUTH.LOGOUT_TEXT" })}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: 16 }}>
          <Button
            color="primary"
            onClick={() => goBack()}
          >
            {props.intl.formatMessage({ id: "AUTH.GENERAL.BACK_BUTTON" })}
          </Button>
          <Button
            variant="contained" color="primary"
            onClick={() => {
              setOpenLogoutModal(false);
              dispatch(actions.logout());
            }}
          >
            {props.intl.formatMessage({ id: "AUTH.LOGOUT_BUTTON" })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default injectIntl(
  (SafeExit)
);
